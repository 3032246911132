.button {
    background: transparent;
    outline: none;
    padding: 0.5em;
    border: none;
    font-size: 2.8em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 500ms ease-in-out;
    box-sizing: border-box;
}

.button_close:hover {
    background: #eee;
}
