.button {
    height: 30px;
    border-radius: 10px;
    width: 100%;
    text-align: left;
    border: 1px solid #ccceee;
    outline: none;
    padding-inline: 5px;
    cursor: pointer;
}

.search_button_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    gap: 10px;
}

.search_button_content_text {
    font-size: 1em;
    line-height: 15px;
    width: 88%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
}

@media screen and (min-width: 768px) {
    .button {
        height: 40px;
        border-radius: 25px;
        padding-inline: 10px;
    }

    .search_button_content_text {
        font-size: 1.125em;
        line-height: 30px;
    }
}
