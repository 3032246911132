.app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    /* position: relative; */
    background: green;
    box-sizing: border-box;
}
