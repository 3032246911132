.routes_desktop {
    display: none;
    visibility: hidden;
    opacity: 0;
}

@media screen and (min-width: 768px) {
    .routes_desktop {
        display: block;
        visibility: visible;
        opacity: 1;
        width: 100%;
        background: #fff;
        padding: 0 0 12px;
        border-radius: 0 0 10px 10px;
        border-top: 8px solid #e8eaed;
        background-color: #f2f4f7;
    }

    .routes_header {
        background: #fff;
        padding: 10px 20px;
        padding-bottom: 16px;
        text-align: left;
        line-height: 16px;
    }

    .title {
        font-size: 2.4em;
    }

    .route_list {
        position: relative;
        display: grid;
        row-gap: 0;
        padding: 0;
        margin: 0;
        pointer-events: all;
    }
}
