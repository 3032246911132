.suggestion {
    /* position: absolute;
    top: -5;
    left: 0; */
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    box-sizing: border-box;
}

.search_item {
    padding: 15px 0px;
    cursor: pointer;
    color: #484848;
    line-height: 16px;
    width: 100%;
    height: 48px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
}

.search_item:not(:last-child) {
    border-bottom: 1px solid hsla(0, 0%, 59.2%, 0.2);
}

.search_item_button {
    outline: none;
    border: none;
    background: none;
    text-align: left;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
