.sidebar_container {
  width: 50px;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  display: none;
  visibility: hidden;
  opacity: 0;
}

.hamburger {
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.line {
  height: 2px;
  background-color: #222;
  width: 100%;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .sidebar_container {
    visibility: visible;
    opacity: 1;
    display: block;
  }
}