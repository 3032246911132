.modal {
    all: unset;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    visibility: hidden;
    opacity: 0;
    transition: all 300ms cubic-bezier(0.18, 0.89, 0.43, 1.19);
    box-sizing: border-box;
}

.modal.show {
    opacity: 1;
    visibility: visible;
    overflow: scroll;
    box-sizing: border-box;
}

.modal_background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    left: 0;
    z-index: 80;
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.18, 0.89, 0.43, 1.19);
    box-sizing: border-box;
}

.modal.show .modal_background {
    opacity: 1;
    box-sizing: border-box;
}

.modal_content {
    background-color: #fff;
    width: 90%;
    min-height: 400px;
    max-height: 600px;
    margin: 10px auto 0;
    box-sizing: border-box;
    opacity: 1;
    border-radius: 10px;
    position: relative;
    z-index: 90;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 2.5em;
    transform: translateY(-200%) scale(0.5);
    transition:
        transform 500ms cubic-bezier(0.18, 0.89, 0.43, 1.19),
        opacity 400ms cubic-bezier(0.18, 0.89, 0.43, 1.19);
    box-sizing: border-box;
}

.fade_in {
    opacity: 1;
    transform: translateY(0) scale(1);
    box-sizing: border-box;
}

.modal_header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
}

@media screen and (min-width: 768px) {
    .modal_content {
        width: 40%;
        box-sizing: border-box;
    }
}
