.autocomplete {
    position: relative;
    box-sizing: border-box;
}

.autocomplete input {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 8px;
    box-sizing: border-box;
}

input::placeholder {
    font-weight: 800;
}

.autocomplete_error {
    margin-top: 10px;
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    box-sizing: border-box;
}

.autocomplete_error span {
    color: red;
    font-size: 2.5em;
}
