.route_list_item {
    position: relative;
    margin: 0;
    padding: 16px;
    background: #fff;
    border-top: 1px solid #d3dae0;
    border-left: 4px solid transparent;
}

.header {
  font-size: 1.25em;
  color: #000;
  line-height: 1.25em;
  display: grid;
  column-gap: 12px;
  font-weight: 900;
  width: 100%;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr;
}

.route_index {
  display: inline-grid;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-items: center;
  background: #859ba6;
  color: #fff;
  border-radius: 50%;
  font-size: 14px;
}

.details {
  margin-top: 5px;
  padding-left: 36px;
  font-size: 1.2em;
  line-height: 1.25em;
}

.footer {
  margin-top: 6px;
  color: #717171;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.route_info {
    display: flex;
    width: 100%;
    padding: 1em 0.5em;
    text-align: left;
    font-size: 2em;
    border: none;
    outline: none;
    flex-direction: column;
    gap: 1em;
}

.current_route {
    border-left-color: #09f;
    background: #f8f9fa;
}

.current_route .route_index {
  background: #09f;
}