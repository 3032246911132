.skeleton_container {
    margin-top: 10px;
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    box-sizing: border-box;
}

.skeleton_item {
    padding-block: 1em;
    margin-bottom: 0.8em;
    height: 30px;
    width: 100%;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 80%
        ),
        #eee;
    background-repeat: repeat-y;
    background-size: 50px 200px;
    background-position: 0 0;
    animation: shine 1s infinite;
}

@keyframes shine {
    to {
        background-position:
            100% 0,
            /* move highlight to right */ 0 0;
    }
}
