.popup_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000000000;
  background-color: rgba(0,0,0,.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar_overlay {
  background-color: transparent;
  transition: background-color 0.2s;
}

.sidebar_overlay.sidebar_open {
  background-color: rgba(0,0,0,.4);
}

.sidebar_open .sidebar {
  transform: translateX(0px);
}

.sidebar {
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: #fff;
  width: 380px;
  transition: transform .2s;
  overflow-y: auto;
  transform: translateX(340px);
  z-index: 4000;
}

.sidebar_header {
  border-bottom: 1px solid #d3dae0;
  padding-block: 20px;
}

.action {
  position: relative;
  width: 25px;
  height: 25px;
}

.action_button {
  position: absolute;
  left: 15px;
  top: 10px;
  border: none;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 0;
  margin: 0;
  background: none;
}


/* .routes {
  border-bottom: 1px solid #d3dae0;
  padding: 10px 20px;
}

.routes_content {}

.routes_header {
  margin-bottom: 2em;
}

.title {
  font-size: 2.8em;
}

.route_list {
  border-left: 2px dashed #000;
  padding-left: 3em;
  position: relative;
}

.route_list::before,
.route_list::after {
  content: " ";
  display: block;
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #ba1212;
  left: -5px;
}

.route_list::before {
  top: 0px;
}

.route_list::after {
  bottom: 0px;
}

.route_list_item {
  margin-bottom: 2em;
  position: relative;
  border-bottom: 1px solid #ccc;
}

.route_list_item::before {
  content: " ";
  display: block;
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #000;
  top: 50px;
  left: -25px;
}

.route_info {
  display: flex;
  width: 100%;
  padding: 1em 0.5em;
  text-align: left;
  font-size: 2em;
  border: none;
  outline: none;
  flex-direction: column;
  gap: 1em;
}

.current_route {
  border: 2px solid red;
}

.current_route.route_list_item::before {
  left: -27px;
}


@media screen and (min-width: 768px) {
  .routes {
    width: 100%;
    max-height: 100%;
  }
} */