.search_area {
    position: sticky;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.search_container {
    position: relative;
    display: flex;
    width: 100%;
    max-height: 100%;
    background: #fff;
    border-radius: 10px;
    pointer-events: all;
    box-sizing: 0 20px 0 rgba(0, 0, 0, 0.3);
}

.with_routes {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
}

.search_content {
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;
}

/* .search_dropdown {
    position: relative;
    background: #e8eaed;
}

.dropdown_list {
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #fff;
    font-family: Rubik, sans-serif;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: static;
    top: 100%;
    z-index: 1000;
    padding-bottom: 8px;
    border-radius: 0 0 10px 10px;
}

.search_item {
    padding: 15px;
    cursor: pointer;
    color: #484848;
    line-height: 16px;
    height: 48px;
    padding-top: 0;
    padding-bottom: 0;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    align-items: center;
    justify-content: flex-start;
}

.search_item.is_current_location {
    grid-template-columns: max-content;
}

.search_item:not(:last-child) {
    border-bottom: 1px solid hsla(0, 0%, 59.2%, 0.2);
}

.search_item_primary {
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
    pointer-events: none;
}

.search_item_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.search_item_secondary {
    font-size: 12px;
    color: #777;
    display: inline;
    pointer-events: none;
} */

.scrollable {
    overflow-y: auto;
}

.sticky_line {
    position: sticky;
    top: 0;
    width: 100%;
    height: 1px;
    background: #d5d7db;
}

.dotted {
    position: absolute;
    top: 45px;
    left: 25px;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.dotted div {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #000;
}

.hamburger_container {
    padding: 5px 20px;
}

.hamburger {
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.line {
    height: 2px;
    background-color: #222;
    width: 100%;
    padding: 0;
    margin: 0;
}

.search_field {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px 10px 10px;
}

@media screen and (min-width: 768px) {
    .search_area {
        position: absolute;
        width: 420px;
        left: 60px;
        bottom: 0;
        padding: 10px;
    }

    .hamburger_container {
        display: none;
        visibility: hidden;
        opacity: 1;
    }

    .search_field {
        padding: 10px 20px;
    }
}
