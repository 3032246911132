.routes_mobile {
    order: 1;
    height: 180px;
    width: 100%;
    background: #fff;
    position: fixed;
    /* top: 50%; */
    /* bottom: 0; */
    left: 0;
    right: 0;
    z-index: 1002;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);
    padding: 10px;
    display: flex;
    align-items: center;
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 180px;
    z-index: 1000;
    box-sizing: border-box;
    background: blue; */
}

.route_content {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow: auto;
    scroll-behavior: smooth;
    background: #f8f9fe;
}

.route_content::-webkit-scrollbar {
    -webkit-appearance: none;
}

.route_list_item {
    display: block;
    min-width: 460px;
    height: 100%;
    margin: 0 10px;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.route_info {
    display: flex;
    width: 100%;
    min-height: 120px;
    padding: 20px;
    text-align: left;
    font-size: 2em;
    border: none;
    outline: none;
    flex-direction: column;
    gap: 1em;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 8px 4px rgba(248, 249, 254, 0.3);
    border-radius: 20px;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .routes_mobile {
        display: none;
        visibility: hidden;
        opacity: 0;
    }
}
